import './App.css';
import { useState,useEffect } from 'react';
import gif from './assets/gifs/what.gif';
import switzerland1 from './assets/images/switzerland-1.jpg';
import switzerland2 from './assets/images/switzerland-2.jpg';
import switzerland3 from './assets/images/switzerland-3.jpg';
import switzerland4 from './assets/images/switzerland-4.jpg';
import switzerland5 from './assets/images/switzerland-5.jpg';
import switzerland6 from './assets/images/switzerland-6.jpg';
import switzerland7 from './assets/images/switzerland-7.jpg';
import switzerland8 from './assets/images/switzerland-8.jpg';
import switzerland9 from './assets/images/switzerland-9.jpg';
import switzerland10 from './assets/images/switzerland-10.jpg';
import switzerland11 from './assets/images/switzerland-11.jpg';
import switzerland12 from './assets/images/switzerland-12.jpg';
import switzerland13 from './assets/images/switzerland-13.jpg';
import switzerland14 from './assets/images/switzerland-14.jpg';
import switzerland15 from './assets/images/switzerland-15.jpg';
import switzerland16 from './assets/images/switzerland-16.jpg';
import switzerland17 from './assets/images/switzerland-17.jpg';
import switzerland18 from './assets/images/switzerland-18.jpg';
import switzerland19 from './assets/images/switzerland-19.jpg';
import switzerland20 from './assets/images/switzerland-20.jpg';
import switzerland21 from './assets/images/switzerland-21.jpg';
import switzerland22 from './assets/images/switzerland-22.jpg';


function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImgSrc, setBackgroundImgSrc] = useState(switzerland1);
  const [seconds, setSeconds] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [days, setDays] = useState("0");
  const [hours, setHours] = useState("0");
  const [months, setMonths] = useState("0");
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [currentDate, setCurrentDate] = useState("May 20, 2024 12:50:00");
  const [isFading, setIsFading] = useState(false);
  const [color, setColor] = useState('#DA291C');

  const setBackgroundImg = (newImgSrc) => {
    setIsFading(true);
  
    setTimeout(() => {
      setBackgroundImgSrc(newImgSrc);
  
      setIsFading(false);
    }, 1500); 
  };
  

  const imageSources = [
    switzerland1,
    switzerland2,
    switzerland3,
    switzerland4,
    switzerland5,
    switzerland6,
    switzerland7,
    switzerland8,
    switzerland9,
    switzerland10,
    switzerland11,
    switzerland12,
    switzerland13,
    switzerland14,
    switzerland15,
    switzerland16,
    switzerland17,
    switzerland18,
    switzerland19,
    switzerland20,
    switzerland21,
    switzerland22,
  ];
  useEffect(() => {
    setBackgroundImg(imageSources[currentImageIndex]);
  }, [currentImageIndex]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current image index and loop back to 0 when it reaches the end
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageSources.length);
    }, 10000); // 10000 milliseconds = 10 seconds
  
    // Clean up the interval on unmount
    return () => clearInterval(interval);
  }, []);
  

  useEffect(() => {
    const interval = setInterval(calculateTime, 1000); // Update every 1 second
    return () => clearInterval(interval);
  }, []);
  
  const calculateTime = () => {
    let CountdownDate = new Date(currentDate).getTime();
    let current = new Date().getTime();
  
    let calculateTime = CountdownDate - current;
  
    if (calculateTime <= 0) {
      setCountdownEnded(true);
      return;
    }
  
    let months = Math.floor(calculateTime / (1000 * 60 * 60 * 24 * 30));
    let days = Math.floor(
      (calculateTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );
    let hours = Math.floor(
      (calculateTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((calculateTime % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((calculateTime % (1000 * 60)) / 1000);
  
    setMonths(months);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };
  
  return (
    <div className={`App`} style={{ backgroundImage: `url(${backgroundImgSrc})` }}>
      {countdownEnded? (     
      <>
        <h1 style={{fontFamily: 'haha', textShadow: '1px 1px 1px white' ,color:color}}>The Countdown is over! have a nice flight!🙄🙄</h1>
        <img src={gif} alt="Countdown Ended GIF" />
      </> 
      
      ) :(
        <div>

        <h1 style={{fontFamily: 'haha', textShadow: '1px 1px 1px white' ,color:color}}>
        CountDown Until Switzerland 🇨🇭
      </h1>
      <div>
        <div>
          <h2 id="month">{months}</h2>
          <br />
          <span style={{textShadow:'3px 3px 3px white'}}>Months</span>
        </div>
        <div>
          <h2 id="day" >{days}</h2>
          <br />
          <span style={{textShadow:'3px 3px 3px white'}}>Days</span>
        </div>
        <div>
          <h2 id="hour">{hours}</h2>
          <br />
          <span style={{textShadow:'3px 3px 3px white'}}>Hours</span>
        </div>
        <div>
          <h2 id="minute" >{minutes}</h2>
          <br />
          <span style={{textShadow:'3px 3px 3px white'}}>Minutes</span>
        </div>
        <div>
          <h2 id="second" >{seconds}</h2>
          <br />
          <span style={{textShadow:'3px 3px 3px white'}}>Seconds</span>
        </div>
      </div>
            </div>
            )}

    </div>
  );
}

export default App;
